/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { FC, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';

import SummaryIncomeProjectionTable from 'components/SummaryIncomeProjectionTable';
import { useNews } from 'containers/Services/NewsService';
import from from 'styles/responsive';
import exportToExcel from 'utils/exportToExcel';
import useMedia from 'utils/useMedia';
import usePlatform from 'utils/usePlatform/usePlatform';

import useConnect from './connect';
import ControlTabFilterYear from './ControlTabFilterYear';
import CustomYear from './CustomYear';
import {
  calculateIncomeTypeAmountByMonthAndEntity,
  calculateIncomeTypeAmountTotals,
  calculateIncomeTypeAmountTotalsByEntity,
  createRowTForExportToExcel,
  getIncomeProjectionYear,
} from './logic';
import {
  BackButton,
  Chevron,
  Container,
  ContainerControls,
  ContainerControlsFilter,
  ContainerControlsLeft,
  ContainerCustomYear,
  ContainerDateControls,
  ContainerTitle,
  Content,
  ExportToExcelButton,
  FilterButton,
  GoBackContainer,
  LoaderContainer,
  ResetLink,
  Spinner,
  TextAmount,
  Title,
  TotalAmount,
  Value,
} from './styles';
import FilterYear from './types';

const IncomeProjection: FC = () => {
  const { replace } = useHistory();
  const isTablet = useMedia(from.tablet.query);
  const newsServices = useNews();

  const { isWeb } = usePlatform();

  const {
    filterEntity,
    filterAccount,
    entitiesOptions,
    accountsOptions,
    incomeProjectionData,
    filterYear,
    customYear,
    handle,
    loading,
    parentUrl,
  } = useConnect();

  useEffect(() => {
    if (newsServices?.notificationToNews) {
      replace('/news');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsServices?.notificationToNews]);

  const rows = calculateIncomeTypeAmountByMonthAndEntity(incomeProjectionData);

  const rowTotalsByEntity =
    calculateIncomeTypeAmountTotalsByEntity(incomeProjectionData);

  const total = useMemo(
    () =>
      incomeProjectionData.reduce((acc, current) => acc + current.amount, 0) ??
      0,
    [incomeProjectionData],
  );

  const handleExportToExcel = () => {
    const rowTotals = calculateIncomeTypeAmountTotals(rows);

    const data = rows
      .flatMap((item) =>
        [
          createRowTForExportToExcel(
            item.name,
            item.incomeTypeAmount,
            item.amount,
            true,
          ),
        ].concat(
          item.rowsByEntity?.incomeTypeAmountByEntity
            .map((entity) => {
              const entitySummary = createRowTForExportToExcel(
                entity.entity,
                entity.incomeTypeAmount,
                entity.amountTotal,
                false,
                1,
              );

              const accounts =
                entity.rowsByAccount?.incomeTypeAmountByAccount.map((account) =>
                  createRowTForExportToExcel(
                    account.account,
                    account.incomeTypeAmount,
                    account.amountTotal,
                    false,
                    2,
                  ),
                ) ?? [];

              return [entitySummary].concat(accounts);
            })
            .flat() ?? [],
        ),
      )
      .concat(createRowTForExportToExcel('Total', rowTotals, total, true))
      .concat(
        rowTotalsByEntity
          ?.map((entity) => {
            const entitySummary = createRowTForExportToExcel(
              entity.entity,
              entity.accumulatedIncomeTypeAmount,
              entity.amountTotal,
              false,
              1,
            );

            const accounts =
              entity.rowsByAccount?.map((account) =>
                createRowTForExportToExcel(
                  account.account,
                  account.incomeTypeAmountTotalsByAccount,
                  account.amountTotal,
                  false,
                  2,
                ),
              ) ?? [];

            return [entitySummary].concat(accounts);
          })
          .flat() ?? [],
      );

    void exportToExcel(
      data,
      `Income Projection List`,
      'IPlist',
      true,
      [2, 3, 4, 5, 6],
    );
  };

  return (
    <>
      {false && (
        <LoaderContainer>
          <Spinner />
        </LoaderContainer>
      )}
      <Container $loading={false}>
        {isTablet && (
          <GoBackContainer>
            <BackButton
              color="secondary"
              variant="only-icon"
              size="small"
              withIcon={<Chevron />}
              href={parentUrl}
            />
          </GoBackContainer>
        )}
        <Content>
          <ContainerTitle>
            <Title>Income Projection</Title>
            <TotalAmount>
              <Value value={total} />
              <TextAmount>{`Total Income (For ${getIncomeProjectionYear(
                customYear?.getFullYear(),
                filterYear,
              )})`}</TextAmount>
            </TotalAmount>
          </ContainerTitle>
          <ContainerControlsLeft>
            <ContainerDateControls>
              <ControlTabFilterYear
                onChange={handle.onChangeFilterYear}
                selectedFilterYear={filterYear}
              />
              <ContainerCustomYear>
                <CustomYear
                  selectedCustomYear={customYear}
                  onChange={handle.onChangeCustomYear}
                  onClear={handle.onClearCustomYear}
                  onFocus={() =>
                    handle.onChangeFilterYear(FilterYear.CUSTOM_YEAR)
                  }
                />
              </ContainerCustomYear>
              <ResetLink onClick={handle.onResetFilterLink}>
                Reset All Filters
              </ResetLink>
            </ContainerDateControls>
          </ContainerControlsLeft>
          <ContainerControls>
            <ContainerControlsFilter>
              <FilterButton
                onChangeMultiple={handle.onChangeFilteringEntity}
                options={
                  entitiesOptions.filter((opt) => opt.value !== 'default') ?? []
                }
                value={filterEntity?.join()}
                placeholder="Entity"
                multiple
                showLeft
                textSmall
              />
              <FilterButton
                onChangeMultiple={handle.onChangeFilteringAccount}
                options={
                  accountsOptions.filter((opt) => opt.value !== 'default') ?? []
                }
                value={filterAccount?.join()}
                placeholder="Account"
                multiple
                showLeft
                textSmall
              />
            </ContainerControlsFilter>
            {isWeb && incomeProjectionData && incomeProjectionData.length > 0 && (
              <ExportToExcelButton
                size="small"
                color="secondary"
                onClick={handleExportToExcel}
              >
                Export to Excel
              </ExportToExcelButton>
            )}
          </ContainerControls>
          <SummaryIncomeProjectionTable
            rows={rows}
            rowTotalsByEntity={rowTotalsByEntity}
            total={total}
            loading={loading}
          />
        </Content>
      </Container>
    </>
  );
};

export default IncomeProjection;
