/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import groupBy from 'lodash/groupBy';
import uniq from 'lodash/uniq';
import { useMemo, useState } from 'react';

import type { EstimatedIncomeAsset } from 'model/EstimatedIncomeAsset';
import type { EstimatedIncomeMonth } from 'model/EstimatedIncomeMonth';
import { formatMonthYear } from 'utils/dates';

import type { IncomeProfitTypes } from './ControlTabProfitTypes/types';
import type { IncomeTaxesTypes } from './ControlTabTaxesTypes/types';
import FilterYear from './types';

export type Option = {
  label: string;
  value: string;
};

export function getEntitiesOptions(data: EstimatedIncomeMonth[]) {
  const entities = uniq(
    data
      .map((dataMonth) =>
        dataMonth.assets
          .filter((item) => item.entity !== '')
          .map((item) => item.entity),
      )
      .flat(),
  );
  return [
    { value: 'default', label: 'All' },
    ...entities.map((item) => ({ value: item, label: item })),
  ];
}

export function getAccountsOptions(data: EstimatedIncomeMonth[]) {
  const accounts = uniq(
    data
      .map((dataMonth) =>
        dataMonth.assets
          .filter((item) => item.account !== '')
          .map((item) => item.account),
      )
      .flat(),
  );
  return [
    { value: 'default', label: 'All' },
    ...accounts.map((item) => ({ value: item, label: item })),
  ];
}

export function applyProfitTypeFilter(
  data: EstimatedIncomeMonth[],
  filter: IncomeProfitTypes,
) {
  return data.map((dataMonth) => {
    const assets =
      filter !== 'all'
        ? dataMonth.assets.filter((item) => item.profitType === filter)
        : dataMonth.assets;
    return {
      id: dataMonth.id,
      eomonthDate: dataMonth.eomonthDate,
      assets,
      amount: assets.reduce(
        (sum, asset) => Number(sum) + Number(asset.amount ?? 0),
        0,
      ),
    };
  });
}

export function applyTaxesTypeFilter(
  data: EstimatedIncomeMonth[],
  filter: IncomeTaxesTypes,
) {
  return data.map((dataMonth) => {
    const assets =
      filter !== 'all'
        ? dataMonth.assets.filter((item) => item.taxesType === filter)
        : dataMonth.assets;
    return {
      id: dataMonth.id,
      eomonthDate: dataMonth.eomonthDate,
      assets,
      amount: assets.reduce(
        (sum, asset) => Number(sum) + Number(asset.amount ?? 0),
        0,
      ),
    };
  });
}

export function applyEntityFilter(
  data: EstimatedIncomeMonth[],
  filterEntity: string[] | undefined,
) {
  if (filterEntity !== undefined && filterEntity.length !== 0) {
    return data.map((dataMonth) => {
      const assets = dataMonth.assets.filter((item) =>
        filterEntity.includes(item.entity),
      );
      return {
        id: dataMonth.id,
        eomonthDate: dataMonth.eomonthDate,
        assets,
        amount: assets.reduce(
          (sum, asset) => Number(sum) + Number(asset.amount ?? 0),
          0,
        ),
      };
    });
  }

  return data;
}

export function applyAccountFilter(
  data: EstimatedIncomeMonth[],
  filterAccount: string[] | undefined,
) {
  if (filterAccount !== undefined && filterAccount.length !== 0) {
    return data.map((dataMonth) => {
      const assets = dataMonth.assets.filter((item) =>
        filterAccount.includes(item.account),
      );
      return {
        id: dataMonth.id,
        eomonthDate: dataMonth.eomonthDate,
        assets,
        amount: assets.reduce(
          (sum, asset) => Number(sum) + Number(asset.amount ?? 0),
          0,
        ),
      };
    });
  }

  return data;
}

export function useDateSelection({
  incomeProjectionDataFiltered,
}: {
  incomeProjectionDataFiltered: readonly EstimatedIncomeMonth[];
}) {
  const dateOptions = useMemo(
    (): readonly [Option, ...Option[]] => [
      {
        label: 'Summary',
        value: 'summary',
      },
      ...incomeProjectionDataFiltered.map(({ eomonthDate }) => ({
        value: `${eomonthDate}`,
        label: `${formatMonthYear(eomonthDate)}`,
      })),
    ],
    [incomeProjectionDataFiltered],
  );

  const [selectedDate, setSelectedDate] = useState(dateOptions[0].value);

  const incomeProjectionDetail = useMemo(
    () =>
      selectedDate === 'summary'
        ? undefined
        : incomeProjectionDataFiltered
            .find((month) => `${month.eomonthDate}` === selectedDate)
            ?.assets?.sort((a, b) => a.name.localeCompare(b.name)),
    [incomeProjectionDataFiltered, selectedDate],
  );

  return {
    dateOptions,
    incomeProjectionDetail,
    selectedDate,
    setSelectedDate,
  };
}

export function getIncomeProjectionYear(
  year: number | undefined,
  filterYear: FilterYear | undefined,
) {
  let yearParse = new Date().getFullYear();

  if (year) {
    yearParse = year;
  } else {
    switch (filterYear) {
      case FilterYear.CUSTOM_YEAR:
        yearParse = new Date().getFullYear();
        break;
      case FilterYear.PRIOR_YEAR:
        yearParse = new Date().getFullYear() - 1;
        break;
      case FilterYear.NEXT_YEAR:
        yearParse = new Date().getFullYear() + 1;
        break;
      default:
        break;
    }
  }

  return yearParse;
}

export function initIncomeTypeAmount() {
  return {
    'Dividend Inc-Taxable': 0,
    'Dividend Inc-Tax Exempt': 0,
    'Int Inc (Coupons)-Taxable': 0,
    'Int Inc (Coupons)-Exempt': 0,
  } as Record<string, number>;
}

export function calculateIncomeTypeAmount(assets: EstimatedIncomeAsset[]) {
  return assets.reduce((acc, asset, index) => {
    if (index === 0) {
      acc = initIncomeTypeAmount();
    }

    if (!acc[asset.incomeType]) {
      acc[asset.incomeType] = 0;
    }
    acc[asset.incomeType] = (acc[asset.incomeType] || 0) + asset.amount;
    return acc;
  }, {} as Record<string, number>);
}

export function calculateIncomeTypeAmountByAccount(
  data: EstimatedIncomeMonth[],
  entity: string,
) {
  return data.map((item) => {
    const groupedByAccount = groupBy(
      item.assets.filter((asset) => asset.entity === entity),
      (asset) => asset.entity && asset.account,
    );

    const incomeTypeAmountByAccount = Object.entries(groupedByAccount).map(
      ([_, assets]) => {
        const incomeTypeAmount = calculateIncomeTypeAmount(assets);

        return {
          account: `${assets[0]?.account || ''}`,
          incomeTypeAmount,
          amountTotal: assets.reduce((acc, asset) => asset.amount + acc, 0),
        };
      },
    );

    return {
      id: item.id,
      name: formatMonthYear(item.eomonthDate),
      incomeTypeAmountByAccount,
    };
  });
}

export function calculateIncomeTypeAmountByEntity(
  data: EstimatedIncomeMonth[],
) {
  return data.map((item) => {
    const groupedByEntity = groupBy(item.assets, (asset) => asset.entity);

    const incomeTypeAmountByEntity = Object.entries(groupedByEntity).map(
      ([_, assets]) => {
        const incomeTypeAmount = calculateIncomeTypeAmount(assets);

        return {
          entity: `${assets[0]?.entity || ''}`,
          incomeTypeAmount,
          rowsByAccount: calculateIncomeTypeAmountByAccount(
            data,
            assets[0]?.entity || '',
          ).find((row) => row.id === item.id),
          amountTotal: assets.reduce((acc, asset) => asset.amount + acc, 0),
        };
      },
    );

    return {
      id: item.id,
      name: formatMonthYear(item.eomonthDate),
      incomeTypeAmountByEntity,
    };
  });
}

export function calculateIncomeTypeAmountByMonthAndEntity(
  data: EstimatedIncomeMonth[],
) {
  return data.map((item) => {
    const incomeTypeAmount = calculateIncomeTypeAmount(item.assets);

    return {
      id: item.id,
      name: formatMonthYear(item.eomonthDate),
      amount: item.amount,
      incomeTypeAmount:
        Object.keys(incomeTypeAmount).length > 0
          ? incomeTypeAmount
          : initIncomeTypeAmount(),
      rowsByEntity: calculateIncomeTypeAmountByEntity(data).find(
        (row) => row.id === item.id,
      ),
    };
  });
}

export function calculateIncomeTypeAmountTotals(data: any) {
  return data.reduce((acc: any, row: any) => {
    Object.entries(row.incomeTypeAmount).forEach(([key, value]) => {
      if (!acc[key]) {
        acc[key] = 0;
      }
      acc[key] += value;
    });
    return acc;
  }, {} as Record<string, number>);
}

export function calculateIncomeTypeAmountTotalsByEntity(
  data: EstimatedIncomeMonth[],
) {
  const allIncomeTypeAmountByEntity = groupBy(
    calculateIncomeTypeAmountByEntity(data).flatMap(
      (row) => row.incomeTypeAmountByEntity,
    ),
    (item) => item.entity,
  );

  return Object.entries(allIncomeTypeAmountByEntity).map(([entity, assets]) => {
    const accumulatedIncomeTypeAmount = assets.reduce((acc, asset) => {
      Object.entries(asset.incomeTypeAmount).forEach(([key, value]) => {
        if (!acc[key]) {
          acc[key] = 0;
        }
        acc[key] += value;
      });
      return acc;
    }, {} as Record<string, number>);

    const rowsByAccounts = calculateIncomeTypeAmountByAccount(
      data,
      entity,
    ).flatMap((row) => row.incomeTypeAmountByAccount);
    const accumulatedIncomeTypeAmountByAccount = Object.entries(
      groupBy(rowsByAccounts, 'account'),
    ).map(([account, assets1]) => {
      const incomeTypeAmountTotalsByAccount = assets1.reduce((acc, asset) => {
        Object.entries(asset.incomeTypeAmount).forEach(([key, value]) => {
          if (!acc[key]) {
            acc[key] = 0;
          }
          acc[key] += value;
        });
        return acc;
      }, {} as Record<string, number>);

      return {
        account,
        incomeTypeAmountTotalsByAccount,
        amountTotal: Object.values(incomeTypeAmountTotalsByAccount).reduce(
          (acc, value) => value + acc,
          0,
        ),
      };
    });

    return {
      entity,
      accumulatedIncomeTypeAmount,
      amountTotal: Object.values(accumulatedIncomeTypeAmount).reduce(
        (acc, value) => value + acc,
        0,
      ),
      rowsByAccount: accumulatedIncomeTypeAmountByAccount,
    };
  });
}

export function createRowTForExportToExcel(
  name: string,
  incomeTypeAmount: Record<string, number>,
  total: number,
  summary: boolean,
  level = 0,
) {
  return {
    Name: name,
    'Dividend Inc-Taxable': incomeTypeAmount['Dividend Inc-Taxable'],
    'Dividend Inc-Tax Exempt': incomeTypeAmount['Dividend Inc-Tax Exempt'],
    'Int Inc (Coupons)-Taxable': incomeTypeAmount['Int Inc (Coupons)-Taxable'],
    'Int Inc (Coupons)-Exempt': incomeTypeAmount['Int Inc (Coupons)-Exempt'],
    Total: total,
    Summary: summary,
    Level: level,
  };
}
