import { FC, memo, useMemo } from 'react';

import BaseAssetDetailTable from 'components/BaseAssetDetailTable';
import BaseAssetDetailTitle from 'components/BaseAssetDetailTitle';
import PositionByAccountAssetTable from 'components/PositionByAccountAssetTable';
import from from 'styles/responsive';
import { formatAmount, formatNumberDecimals } from 'utils/amounts';
import { formatDate } from 'utils/dates';
import up from 'utils/paths';
import { formatPercentage } from 'utils/percentages';
import useMedia from 'utils/useMedia';

import {
  Button,
  Chevron,
  Container,
  GoBackContainer,
  Header,
  Title,
  Variant,
} from './styles';
import type { Props } from './types';

const PrivateInvestmentsAssetDetailTable: FC<Props> = ({
  capitalCalled,
  commitment,
  distributions,
  marketValue,
  MOI,
  parentUrl,
  title,
  toBeCalled,
  totalPortfolioPercent,
  totalPortfolioAtCostPercent,
  contDollars,
  valueDate,
  closingDate,
  endOfInvPeriodDate,
  maximumLiquidityDate,
  positionByAccountAssets,
  url = '',
}) => {
  const isTablet = useMedia(from.tablet.query);

  const assetDetailsRows = useMemo(
    () => [
      {
        id: 1,
        key: 'Market Value',
        value: formatAmount(marketValue),
      },
      {
        id: 2,
        key: '(%) Total Portfolio at Market Value',
        value: formatPercentage(totalPortfolioPercent),
      },
      {
        id: 3,
        key: '(%) Total Portfolio at Cost',
        value: formatPercentage(totalPortfolioAtCostPercent),
      },
      {
        id: 4,
        key: 'Commitment',
        value: formatAmount(commitment),
      },
      {
        id: 5,
        key: 'Capital Called',
        value: formatAmount(capitalCalled),
      },
      {
        id: 6,
        key: 'To Be Called',
        value: formatAmount(toBeCalled),
      },
      {
        id: 7,
        key: 'Distributions',
        value: formatAmount(distributions),
      },
      {
        id: 8,
        key: 'DPI',
        value: formatNumberDecimals(distributions / capitalCalled),
      },
      {
        id: 9,
        key: 'Total Profit / Loss',
        value: formatAmount(contDollars),
        isColored: true,
        isNegative: contDollars < 0,
      },
      {
        id: 10,
        key: 'MOI',
        value: formatNumberDecimals(MOI),
      },
    ],
    [
      MOI,
      capitalCalled,
      commitment,
      contDollars,
      distributions,
      marketValue,
      toBeCalled,
      totalPortfolioAtCostPercent,
      totalPortfolioPercent,
    ],
  );

  const characteristicsRows = useMemo(
    () => [
      {
        id: 1,
        key: 'Valuation Date',
        value: valueDate > 0 ? formatDate(valueDate) : 'N/A',
      },
      {
        id: 2,
        key: 'Closing Date',
        value: closingDate > 0 ? formatDate(closingDate) : 'N/A',
      },
      {
        id: 3,
        key: 'End of Investment Period',
        value: endOfInvPeriodDate > 0 ? formatDate(endOfInvPeriodDate) : 'N/A',
      },
      {
        id: 4,
        key: 'Maximum Liquidity Date',
        value:
          maximumLiquidityDate > 0 ? formatDate(maximumLiquidityDate) : 'N/A',
      },
    ],
    [closingDate, endOfInvPeriodDate, maximumLiquidityDate, valueDate],
  );

  return (
    <Container>
      <Header id="header">
        {isTablet && (
          <GoBackContainer>
            <Button
              color="secondary"
              variant="only-icon"
              size="small"
              withIcon={<Chevron />}
              href={parentUrl ?? up(url, 2)}
            />
            <Variant>Private Investments</Variant>
          </GoBackContainer>
        )}
        <Title>{title}</Title>
        <BaseAssetDetailTitle title="Asset Details" />
      </Header>
      <BaseAssetDetailTable rows={assetDetailsRows} />
      <Header>
        <BaseAssetDetailTitle title="Characteristics" />
      </Header>
      <BaseAssetDetailTable rows={characteristicsRows} />
      {isTablet && (
        <>
          <Header>
            <BaseAssetDetailTitle title="Position by Account" />
          </Header>
          <PositionByAccountAssetTable data={positionByAccountAssets} />
        </>
      )}
    </Container>
  );
};

export default memo(PrivateInvestmentsAssetDetailTable);
