/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';

const cellBorder = {
  top: { style: 'thin' },
  left: { style: 'thin' },
  bottom: { style: 'thin' },
  right: { style: 'thin' },
};

export default async function exportToExcel(
  data: any,
  sheetName: string,
  fileName: string,
  headerColored: boolean,
  columnsToCurrency: number[],
  columnsToPercent: number[] = [],
  columnsToNumber: number[] = [],
) {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(sheetName);

  if (data && data.length > 0) {
    const keys = Object.keys(data[0]).filter(
      (key) => key !== 'Summary' && key !== 'Level',
    );
    worksheet.addRow(keys);

    const { lastRow: headerRow } = worksheet;
    if (headerRow) {
      if (headerColored) {
        headerRow.border = cellBorder as any;
        headerRow.font = { bold: true, color: { argb: 'FFFFFFFF' } };
        headerRow.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF113768' },
        };
      }
      headerRow.alignment = { vertical: 'middle', horizontal: 'center' };
      headerRow.height = 24;
      headerRow.border = cellBorder as any;
    }

    data.forEach((row: any) => {
      const rowData: any = [];
      keys.forEach((key) => {
        rowData.push(row[key]);
      });
      worksheet.addRow(rowData);

      const { lastRow } = worksheet;
      if (lastRow) {
        if (row.Summary) {
          lastRow.font = { color: { argb: 'FFFFFFFF' } };
          lastRow.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF113768' },
          };
          lastRow.height = 18;
        } else {
          lastRow.height = 15;
        }

        if (row.Level) {
          const firstCell = lastRow.getCell(1);
          firstCell.value = `${' '.repeat(Math.pow(3, row.Level))}${
            firstCell.value
          }`;

          if (row.Level === 1) {
            lastRow.font = { bold: true };
          }
        }

        lastRow.border = cellBorder as any;
      }
    });

    columnsToPercent.forEach((colIdx) => {
      worksheet
        .getColumn(colIdx)
        .eachCell({ includeEmpty: true }, (_, rowNumber) => {
          if (rowNumber > 1) {
            const currentCell = worksheet.getCell(rowNumber, colIdx);
            currentCell.numFmt = '0.00%';
          }
        });
    });

    columnsToNumber.forEach((colIdx) => {
      worksheet
        .getColumn(colIdx)
        .eachCell({ includeEmpty: true }, (_, rowNumber) => {
          if (rowNumber > 1) {
            const currentCell = worksheet.getCell(rowNumber, colIdx);
            currentCell.numFmt = '#,##0.00';
          }
        });
    });

    columnsToCurrency.forEach((colIdx) => {
      worksheet
        .getColumn(colIdx)
        .eachCell({ includeEmpty: true }, (_, rowNumber) => {
          if (rowNumber > 1) {
            const currentCell = worksheet.getCell(rowNumber, colIdx);
            currentCell.numFmt = '"$" #,##0.00_);[Red]("$" #,##0.00)';
          }
        });
    });

    worksheet.columns.forEach((_, colIdx) => {
      let maxLength = 0;
      const column = worksheet.getColumn(colIdx + 1);

      column.eachCell({ includeEmpty: true }, (cell) => {
        maxLength = Math.max(
          15,
          maxLength,
          cell.value ? cell.value.toString().length : 15,
        );
      });

      column.width = maxLength;
    });

    const footerRow = worksheet.addRow([worksheet.lastRow?.number ?? 0 + 1]);
    worksheet.mergeCells(
      footerRow.number,
      worksheet.getColumn(1).number,
      footerRow.number + 9,
      worksheet.lastColumn?.number ?? 0,
    );
    footerRow.getCell(1).value =
      'The information presented in the CV App is unaudited and is current only as of the date and time it is viewed by the Client. Nothing contained herein represents an official document for income tax reporting purposes and should not be relied upon for such purposes, including determination of income, cost basis, amortization, or gain/loss. Such information, which may be inaccurate, incomplete, or subject to updating, should be confirmed with the Client’s official custodian statements and confirmations, as well as your tax advisor.';
    footerRow.alignment = { wrapText: true, vertical: 'middle' };
    footerRow.border = cellBorder as any;

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(blob, `${fileName}_${new Date().getTime()}.xlsx`);
  }
}
