import { FC, memo, useMemo } from 'react';

import BaseAssetDetailTable from 'components/BaseAssetDetailTable';
import BaseAssetDetailTitle from 'components/BaseAssetDetailTitle';
import PositionByAccountAssetTable from 'components/PositionByAccountAssetTable';
import from from 'styles/responsive';
import {
  formatAmount,
  formatAmountWithDecimals,
  formatNumberDecimals,
} from 'utils/amounts';
import up from 'utils/paths';
import useMedia from 'utils/useMedia';

import {
  Button,
  Chevron,
  Container,
  GoBackContainer,
  Header,
  Shares,
  Title,
  Variant,
} from './styles';
import type { Props } from './types';

const RegularAssetDetailTable: FC<Props> = ({
  categoryName,
  dividendsReceived,
  marketPrice,
  marketValue,
  cost,
  parentUrl,
  shares,
  title,
  totalProfitLoss,
  positionByAccountAssets,
  url = '',
}) => {
  const isTablet = useMedia(from.tablet.query);

  const rows = useMemo(
    () => [
      {
        id: 1,
        key: 'Market Price',
        value: formatAmountWithDecimals(marketPrice),
      },
      {
        id: 2,
        key: 'Market Value',
        value: formatAmount(marketValue),
      },
      {
        id: 3,
        key: 'Cost',
        value: formatAmount(cost),
      },
      {
        id: 4,
        key: 'Average Cost per Share',
        value: formatAmountWithDecimals(cost / shares),
      },
      {
        id: 5,
        key: 'Dividends Received',
        value: formatAmount(dividendsReceived),
      },
      {
        id: 6,
        key: 'Total Profit / Loss',
        value: formatAmount(totalProfitLoss),
        isColored: true,
        isNegative: totalProfitLoss < 0,
      },
    ],
    [
      cost,
      dividendsReceived,
      marketPrice,
      marketValue,
      shares,
      totalProfitLoss,
    ],
  );

  return (
    <Container>
      <Header id="header">
        {isTablet && (
          <GoBackContainer>
            <Button
              color="secondary"
              variant="only-icon"
              size="small"
              withIcon={<Chevron />}
              href={parentUrl ?? up(url, 2)}
            />
            <Variant>{categoryName}</Variant>
          </GoBackContainer>
        )}
        <Title>{title}</Title>
        <Shares>{formatNumberDecimals(shares)} shares</Shares>
        <BaseAssetDetailTitle title="Asset Details" />
      </Header>
      <BaseAssetDetailTable rows={rows} />
      {isTablet && (
        <>
          <Header>
            <BaseAssetDetailTitle title="Position by Account" />
          </Header>
          <PositionByAccountAssetTable data={positionByAccountAssets} />
        </>
      )}
    </Container>
  );
};

export default memo(RegularAssetDetailTable);
