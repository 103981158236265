import styled, { css } from 'styled-components';

import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';
import Amount from 'components/Amount';
import Button from 'components/Button';
import Select from 'components/Select';
import DefaultSpinner from 'components/Spinner';
import PageLayout from 'containers/Common/PageLayout';
import from from 'styles/responsive';

export const LoaderContainer = styled.div`
  position: sticky;
  top: 50%;
  padding-left: 50%;
  z-index: 1000;
`;

export const Spinner = styled(DefaultSpinner)``;

export const Container = styled(PageLayout)<{
  $loading: boolean;
}>`
  ${({ $loading }) =>
    $loading &&
    css`
      pointer-events: none;
      filter: blur(1px);
    `};
`;

export const GoBackContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

export const BackButton = styled(Button)`
  margin-right: 16px;
`;

export const Chevron = styled(ChevronLeft)`
  margin-top: 2px;
`;

export const Content = styled.div``;

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

export const TotalAmount = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 0.625rem;
`;

export const TextAmount = styled.h4`
  margin: 0 0 0 0.725rem;
  font-size: 0.825rem;
  color: ${({ theme }) => theme.colors.white75};
  font-weight: ${({ theme }) => theme.weights.regular};
  line-height: 1.225rem;
`;

export const Value = styled(Amount)`
  font-size: 1.75rem;
  font-weight: ${({ theme }) => theme.weights.bold};
`;

export const Title = styled.h2`
  display: none;
  margin: 0;
  margin-top: 1rem;
  ${from.tablet`
    display: block;
    grid-column: span 2;
    color: ${({ theme }) => theme.colors.white};
    font-size: 1.75rem;
    line-height: 1.4;
  `}
`;

export const ContainerControlsLeft = styled.div`
  margin: 0 0 1.625rem;
  display: flex;
  align-items: center;
`;

export const ContainerControls = styled.div`
  margin: 0 0 1.625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContainerDateControls = styled.div`
  display: flex;
  align-items: center;
`;

export const ContainerControlsFilter = styled.div`
  display: flex;
  align-items: center;
`;

export const FilterButton = styled(Select)`
  width: fit-content;
  margin-right: 0.75rem;
`;

export const ExportToExcelButton = styled(Button)`
  min-width: 8.625rem;
`;

export const ContainerCustomYear = styled.div`
  margin-left: 2.5rem;
`;

export const ResetLink = styled.div`
  cursor: pointer;
  margin-left: 2.5rem;
  font-size: 1rem;
  line-height: 1.1;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.weights.regular};

  & path {
    fill: ${({ theme }) => theme.colors.primary};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primary75};

    & path {
      fill: ${({ theme }) => theme.colors.primary75};
    }
  }
`;
