import { Route } from 'react-router-dom';

import IncomeProjection from '../IncomeProjection';
import AllocationsRoot from './AllocationsRoot';
import AssetAllocationHistory from './AssetAllocationHistory';
import AssetDetail from './AssetDetail';
import AssetDetailFixedIncome from './AssetDetailFixedIncome';
import AssetDetailPrivateInvestments from './AssetDetailPrivateInvestments';
import BondPerformanceAnalysis from './BondPerformanceAnalysis';
import CashLiquidity from './CashLiquidity';
import CategoryDetail from './CategoryDetail';
import CategoryDetailCash from './CategoryDetailCash';
import CategoryDetailFixedIncome from './CategoryDetailFixedIncome';
import CategoryDetailPrivateInvestments from './CategoryDetailPrivateInvestments';
import CategoryDetailSummary from './CategoryDetailSummary';
import DetailCashSummary from './DetailCashSummary';
import FixedIncomeCharacteristics from './FixedIncomeCharacteristics';
import PrivateInvestmentsSummary from './PrivateInvestmentsSummary';
import type { Props } from './types';

function Allocations({ path }: Props) {
  return [
    // Asset Allocation History
    <Route
      component={AssetAllocationHistory}
      key="asset-allocation-history"
      exact
      path={`${path}/asset-allocation-history`}
    />,
    <Route
      component={IncomeProjection}
      key="income-projection"
      exact
      path={`${path}/income-projection`}
    />,
    // Cash Liquidity
    <Route
      component={CashLiquidity}
      key="cash-liquidity"
      exact
      path={`${path}/categories/cash/liquidity`}
    />,
    <Route
      component={DetailCashSummary}
      key="cash-liquidity-summary"
      exact
      path={`${path}/categories/cash/summary`}
    />,
    // Private Investments Table
    <Route
      component={PrivateInvestmentsSummary}
      key="private-investments-summary"
      exact
      path={`${path}/categories/private-investments/summary`}
    />,
    // Fixed Income Characteristics
    <Route
      component={FixedIncomeCharacteristics}
      key="fixed-income-characteristics"
      exact
      path={`${path}/categories/fixed-income/fixed-income-characteristics`}
    />,
    // Bond Performance Analysis
    <Route
      component={BondPerformanceAnalysis}
      key="bond-performance-analysis"
      exact
      path={`${path}/categories/fixed-income/bond-performance-analysis`}
    />,
    // Asset details
    <Route
      component={AssetDetailPrivateInvestments}
      key="asset-detail-private-investments"
      exact
      path={`${path}/categories/private-investments/assets/:assetSlug`}
    />,
    <Route
      component={AssetDetailFixedIncome}
      key="asset-detail-fixed-income"
      exact
      path={`${path}/categories/fixed-income/assets/:assetSlug`}
    />,
    <Route
      component={AssetDetail}
      key="asset-detail"
      exact
      path={`${path}/categories/:categorySlug/assets/:assetSlug`}
    />,
    // Category detail
    <Route
      component={CategoryDetailFixedIncome}
      key="category-detail-fixed-income"
      exact
      path={`${path}/categories/fixed-income`}
    />,
    <Route
      component={CategoryDetailPrivateInvestments}
      key="category-detail-private-investments"
      exact
      path={`${path}/categories/private-investments`}
    />,
    <Route
      component={CategoryDetailCash}
      key="category-detail-cash"
      exact
      path={`${path}/categories/cash`}
    />,
    <Route
      component={CategoryDetail}
      key="category-detail"
      exact
      path={`${path}/categories/:categorySlug`}
    />,
    <Route
      component={CategoryDetailSummary}
      key="category-detail-summary"
      exact
      path={`${path}/categories/:categorySlug/summary`}
    />,
    // Root
    <Route
      component={AllocationsRoot}
      key="allocations-root"
      exact
      path={path}
    />,
  ];
}

export default Allocations;
