import { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import useSelectedClient from 'api/hooks/clients/useSelectedClient';
import useEstimatedIncome from 'api/hooks/incomeProjection/useEstimatedIncome';
import type { EstimatedIncomeMonth } from 'model/EstimatedIncomeMonth';
import up from 'utils/paths';

import {
  applyAccountFilter,
  applyEntityFilter,
  getAccountsOptions,
  getEntitiesOptions,
  getIncomeProjectionYear,
} from './logic';
import FilterYear from './types';

export default function useConnect() {
  const { url } = useRouteMatch();
  const { selectedPortfolio } = useSelectedClient();
  const [loading, setLoading] = useState<boolean>(true);

  const [filterEntity, setFilterEntity] = useState<string[] | undefined>(
    undefined,
  );
  const [filterAccount, setFilterAccount] = useState<string[] | undefined>(
    undefined,
  );

  const [filterYear, setFilterYear] = useState<FilterYear>(
    FilterYear.CURRENT_YEAR,
  );
  const [customYear, setCustomYear] = useState<Date | undefined>(undefined);

  const [incomeProjectionDataFiltered, setIncomeProjectionDataFiltered] =
    useState<EstimatedIncomeMonth[]>([]);

  const { data: incomeProjection } = useEstimatedIncome(
    selectedPortfolio?.id,
    getIncomeProjectionYear(customYear?.getFullYear(), filterYear),
  );

  useEffect(() => {
    if (selectedPortfolio?.id && incomeProjection) {
      setLoading(false);
      setIncomeProjectionDataFiltered(incomeProjection ?? []);
    } else {
      setLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterYear, incomeProjection, selectedPortfolio]);

  useEffect(() => {
    if (selectedPortfolio?.id) {
      onResetFilterLink();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPortfolio]);

  useEffect(() => {
    if (incomeProjection) {
      setLoading(false);
      let dataFiltered = incomeProjection;
      dataFiltered = applyEntityFilter(dataFiltered, filterEntity);
      dataFiltered = applyAccountFilter(dataFiltered, filterAccount);
      setIncomeProjectionDataFiltered([...dataFiltered]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterEntity, filterAccount]);

  const onChangeFilteringEntity = useCallback(
    (newValue: string[] | undefined) => {
      if (newValue?.length === 0 || newValue === undefined) {
        setFilterEntity(undefined);
      } else {
        setFilterEntity(newValue);
      }
    },
    [setFilterEntity],
  );

  const onChangeFilteringAccount = useCallback(
    (newValue: string[] | undefined) => {
      if (newValue?.length === 0 || newValue === undefined) {
        setFilterAccount(undefined);
      } else {
        setFilterAccount(newValue);
      }
    },
    [setFilterAccount],
  );

  const onChangeFilterYear = (value: FilterYear) => {
    setFilterYear(value);
    setCustomYear(undefined);
  };

  const onChangeCustomYear = (value: Date | undefined) => {
    setFilterYear(FilterYear.CUSTOM_YEAR);
    setCustomYear(value);
  };

  const onClearCustomYear = () => {
    setFilterYear(FilterYear.CURRENT_YEAR);
    setCustomYear(undefined);
  };

  const onResetFilterLink = () => {
    onChangeFilteringEntity(undefined);
    onChangeFilteringAccount(undefined);
    onChangeFilterYear(FilterYear.CURRENT_YEAR);
  };

  const parentUrl = up(url);

  return {
    incomeProjectionData: incomeProjectionDataFiltered,
    filterEntity,
    filterAccount,
    entitiesOptions: getEntitiesOptions(incomeProjection ?? []),
    accountsOptions: getAccountsOptions(incomeProjection ?? []),
    filterYear,
    customYear,
    handle: {
      onChangeFilteringEntity,
      onChangeFilteringAccount,
      onChangeFilterYear,
      onChangeCustomYear,
      onClearCustomYear,
      onResetFilterLink,
    },
    loading,
    parentUrl,
  };
}
