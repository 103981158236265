import styled from 'styled-components';

import from from 'styles/responsive';

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Table = styled.div`
  display: grid;
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.75rem;
  line-height: 1.1875rem;
  color: ${({ theme }) => theme.colors.white};

  > :nth-child(even) {
    background: ${({ theme }) => theme.colors.dark1};
  }

  ${from.laptop`
    font-size: 0.875rem;
  `}
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 0.6fr) repeat(5, 0.4fr);
  column-gap: 1rem;
  padding: 1rem;
  color: ${({ theme }) => theme.colors.white75};
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  text-align: left;
  position: sticky;
  top: 0;
  background: ${({ theme }) => theme.colors.dark2};

  > :nth-child(n + 4) {
    text-align: center;
  }
`;

export const HeaderCell = styled.div`
  font-size: 0.75rem;
  line-height: 1.0625rem;

  ${from.laptop`
    font-size: 0.875rem;
  `}
`;

export const HeaderTitle = styled.span`
  cursor: pointer;
  display: inline-flex;
`;

export const Title = styled.span`
  width: max-content;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 0.6fr) repeat(5, 0.4fr);
  column-gap: 1rem;
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.white10};
  padding: 1rem;
`;

export const ColCenter = styled.div`
  text-align: center;
`;

export const ColLeft = styled.div`
  text-align: left;
`;
