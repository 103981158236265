import { Capacitor } from '@capacitor/core';
import { Formik } from 'formik';
import { FC, useEffect, useState } from 'react';

import Allocation from 'components/Allocation';
import SortInfo from 'components/SortInfo';
import SortItemsGroup from 'components/SortItemsGroup';
import SortModal from 'components/SortModal';
import { useAssetContext } from 'context/Asset';
import from from 'styles/responsive';
import { formatDate } from 'utils/dates';
import exportToExcel from 'utils/exportToExcel';
import searchWithoutSort from 'utils/searchWithoutSort';
import { KeySortOfColumn, OrderOrientation } from 'utils/sort/types';
import useMedia from 'utils/useMedia';
import usePlatform from 'utils/usePlatform/usePlatform';

import useConnect from './connect';
import {
  ActionZone,
  Container,
  Content,
  ExportToExcelButton,
  FilterButton,
  FilterModalTitle,
  Form,
  Grid,
  Header,
  ListContainer,
  ModalTitle,
  Navigation,
  PrivateInvestmentsList,
  Stats,
  TableButton,
  TableContainer,
  Title,
  Toolbar,
} from './styles';

const CategoryDetailPrivateInvestments: FC = () => {
  const { asset, setAsset } = useAssetContext();
  const [sortColumn, setSortColumn] = useState<string>('Total ($)');
  const [sortOrientation, setSortOrientation] = useState<string>(
    OrderOrientation.DESCENDENT,
  );
  const {
    allocation,
    allocationChartData,
    assets,
    backUrl,
    balance,
    breadcrumbs,
    currentFilteringSubtypes,
    currentFilteringSponsors,
    currentSorting,
    filterSubtypesOptions,
    filterSponsorsOptions,
    handle,
    initialValues,
    modalOpen,
    MOI,
    navigationLinks,
    pageTitle,
    selectedUrl,
    sortOptions,
    headerColumns,
    summary,
    url,
    search,
  } = useConnect();

  const isMobile = !useMedia(from.tablet.query);
  const { isWeb } = usePlatform();

  useEffect(() => {
    const currentSortingAux = currentSorting ?? 'default';

    const headerColumn = headerColumns.find(
      (col) =>
        col.keyOrderAsc === currentSortingAux ||
        col.keyOrderDesc === currentSortingAux,
    );

    if (headerColumn) {
      setSortColumn(headerColumn.titleColumn);
      setSortOrientation(
        headerColumn.keyOrderAsc === currentSortingAux
          ? OrderOrientation.ASCENDENT
          : OrderOrientation.DESCENDENT,
      );
    }
  }, [headerColumns, currentSorting]);

  useEffect(() => {
    if (asset && assets && Capacitor.getPlatform() !== 'web') {
      setTimeout(() => {
        document.getElementById(asset)?.scrollIntoView({ block: 'center' });
        setAsset('');
      }, 0);
    }
  }, [asset, setAsset, assets]);

  useEffect(() => {
    if (assets.length) {
      const itemPosition = sessionStorage.getItem('itemPosition');
      if (itemPosition) {
        setTimeout(() => {
          document
            .getElementById(itemPosition)
            ?.scrollIntoView({ block: 'center' });
          sessionStorage.removeItem('itemPosition');
        }, 0);
      }
    }
  }, [assets]);

  const handleSortByColumn = (sortByColumnSelected: KeySortOfColumn) => {
    if (sortByColumnSelected.titleColumn === sortColumn) {
      handle.changeSorting(
        sortOrientation === OrderOrientation.DESCENDENT
          ? sortByColumnSelected.keyOrderAsc
          : sortByColumnSelected.keyOrderDesc,
      );
    } else if (sortByColumnSelected.titleColumn !== sortColumn) {
      handle.changeSorting(sortByColumnSelected.keyOrderDesc);
    }
  };

  const applyFilterSponsors = (value: string[]) => {
    handle.changeFilteringSponsors(value.join());
  };

  const applyFilterSubtypes = (value: string[]) => {
    handle.changeFilteringSubtypes(value.join());
  };

  const handleExportToExcel = () => {
    const data = assets.map((item) => ({
      Name: item.name,
      Sponsor: item.sponsor,
      Subtype: item.subtype,
      Price_As_Of_Date:
        item.priceAsOfDate > 0 ? formatDate(item.priceAsOfDate) : 'N/A',
      Valuation_Date: item.valueDate > 0 ? formatDate(item.valueDate) : 'N/A',
      Price: item.marketPrice,
      Market_Value: item.marketValue,
      Total_Porffolio_At_Market_Value: item.totalPortfolioPercent,
      Total_Portfolio_At_Cost: item.totalPortfolioAtCostPercent,
      Commitment: item.capitalCommitment,
      Capital_Called: item.capitalCalled,
      To_Be_Called: item.toBeCalled,
      Distributions: item.distributions,
      Total_Profit_and_Loss: item.contDollars,
      MOI: item.moi,
      Closing_Date: item.closingDate > 0 ? formatDate(item.closingDate) : 'N/A',
      End_of_Investment_Period:
        item.endOfInvPeriodDate > 0
          ? formatDate(item.endOfInvPeriodDate)
          : 'N/A',
      Maximun_Liquidity_Date:
        item.maximumLiquidityDate > 0
          ? formatDate(item.maximumLiquidityDate)
          : 'N/A',
      Status: !item.closedPosition ? 'Open' : 'Closed',
    }));

    void exportToExcel(
      data,
      'Private Investments List',
      'PIlist',
      false,
      [6, 7, 10, 11, 12, 13, 14],
      [8, 9],
      [15],
    );
  };

  return (
    <Container
      parentUrl={backUrl}
      showLayoutHeader={!modalOpen}
      title={pageTitle}
      /*
       * This is a dirty hack that solves an issue on Safari on the mobile
       * viewport where after closing the sort/filter modal the app got stuck
       * on a blue screen.
       *
       * It seems that in that state, removing a css prop forces ionic/safari
       * to show the content correctly.
       *
       * Anyway, this line solves it, we discourage you from touching it or
       * things will break.
       */
      $enableCSSPropAsWorkaround={modalOpen}
    >
      <Grid>
        <Header
          allocation={<Allocation value={allocation} />}
          amount={balance}
          chartData={allocationChartData}
          title={pageTitle}
          modalOpen={modalOpen}
        />
        {!isMobile && (
          <Navigation
            activeLink={selectedUrl}
            breadcrumbs={breadcrumbs}
            links={navigationLinks}
          />
        )}
        <Content>
          {!isMobile && (
            <Toolbar>
              <Title>Summary</Title>
              <TableButton
                color="secondary"
                size="small"
                withCaretDown
                href={`${url}/summary${searchWithoutSort(search)}`}
              >
                Private Investments Table
              </TableButton>
            </Toolbar>
          )}
          <Stats
            marketValue={summary.value}
            totalPortfolioPercent={summary.allocation}
            commitment={summary.capitalCommitment}
            capitalCalled={summary.grossCapitalCall}
            toBeCalled={summary.toBeCalled}
            distributions={summary.distributions}
            contDollars={summary.contDollars}
            MOI={MOI}
            pathname={`${url}/summary${search}`}
          />
          {!isMobile && (
            <Toolbar>
              <Title>Assets</Title>
              <ActionZone>
                {isWeb && assets && assets.length > 0 && (
                  <ExportToExcelButton
                    size="small"
                    color="secondary"
                    onClick={handleExportToExcel}
                  >
                    Export to Excel
                  </ExportToExcelButton>
                )}
                <FilterButton
                  onChangeMultiple={applyFilterSponsors}
                  options={filterSponsorsOptions.filter(
                    (opt) => opt.value !== 'default',
                  )}
                  placeholder="Sponsors"
                  value={currentFilteringSponsors}
                  multiple
                />
                <FilterButton
                  onChangeMultiple={applyFilterSubtypes}
                  options={filterSubtypesOptions.filter(
                    (opt) => opt.value !== 'default',
                  )}
                  placeholder="Subtypes"
                  value={currentFilteringSubtypes}
                  multiple
                />
              </ActionZone>
            </Toolbar>
          )}
          <TableContainer>
            <ListContainer>
              <SortInfo
                currentFilteringSubtypes={currentFilteringSubtypes}
                currentFilteringSponsors={currentFilteringSponsors}
                currentSorting={currentSorting}
                filterSubtypesOptions={filterSubtypesOptions}
                filterSponsorsOptions={filterSponsorsOptions}
                sortOptions={sortOptions}
              />
              <PrivateInvestmentsList
                data={assets}
                isMobile={isMobile}
                textButton="Sort/Filter"
                openModal={handle.openModal}
                handleSortByColumn={handleSortByColumn}
                sortApplied={{
                  titleColumn: sortColumn,
                  orderOrientation: sortOrientation as OrderOrientation,
                }}
                headerColumns={headerColumns}
              />
            </ListContainer>
          </TableContainer>
        </Content>
      </Grid>
      {modalOpen && (
        <Formik
          initialValues={initialValues}
          onSubmit={handle.submitForm}
          enableReinitialize
        >
          <Form>
            <SortModal onClose={handle.closeModal}>
              <>
                <ModalTitle>Sort by</ModalTitle>
                <SortItemsGroup options={sortOptions} name="sortValue" />
                <FilterModalTitle>Filter by Subtypes</FilterModalTitle>
                <SortItemsGroup
                  options={filterSubtypesOptions}
                  name="filterSubtypesValue"
                  multiple
                />
                <FilterModalTitle>Filter by Sponsors</FilterModalTitle>
                <SortItemsGroup
                  options={filterSponsorsOptions}
                  name="filterSponsorsValue"
                  multiple
                />
              </>
            </SortModal>
          </Form>
        </Formik>
      )}
    </Container>
  );
};
export default CategoryDetailPrivateInvestments;
