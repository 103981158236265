import styled from 'styled-components';

import { ReactComponent as DefaultMinusIcon } from 'assets/icons/minus.svg';
import { ReactComponent as DefaultPlusIcon } from 'assets/icons/plus.svg';
import DefaultSpinner from 'components/Spinner';

export const Table = styled.div`
  display: grid;
  color: ${({ theme }) => theme.colors.white};
  font-size: 1rem;
  line-height: 1.1875rem;
  color: ${({ theme }) => theme.colors.white};
  > :nth-child(even) {
    background: ${({ theme }) => theme.colors.dark1};
  }
`;

export const Header = styled.div<{
  $incomeTypeLength?: number;
}>`
  display: grid;
  grid-template-columns:
    1.2fr repeat(${({ $incomeTypeLength = 4 }) => $incomeTypeLength}, 1fr)
    1fr 1.5rem;
  column-gap: 1.5rem;
  padding: 1rem 1.5rem;
  background-color: ${({ theme }) => theme.colors.dark3};
  color: ${({ theme }) => theme.colors.white75};
  border-bottom: 1px solid ${({ theme }) => theme.colors.white10};
  font-weight: ${({ theme }) => theme.weights.bold};
  text-align: center;
  > :first-child {
    text-align: left;
  }
  > :last-child {
    text-align: right;
  }
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const HeaderCell = styled.div`
  font-size: 0.875rem;
  line-height: 1.0625rem;
`;

export const Row = styled.div<{
  $selectedRow?: boolean;
  $incomeTypeLength?: number;
  $isLastRow?: boolean;
}>`
  display: grid;
  grid-template-columns:
    1.2fr repeat(${({ $incomeTypeLength = 4 }) => $incomeTypeLength}, 1fr)
    1fr 1.5rem;
  column-gap: 1.5rem;
  border-bottom: ${({ $selectedRow = false }) => ($selectedRow ? '2px' : '1px')}
    solid
    ${({ theme, $selectedRow = false }) =>
      $selectedRow ? theme.colors.white : theme.colors.white10};
  padding: 1rem 1.5rem;
  text-align: center;
  font-size: 1.1rem;
  > :first-child {
    text-align: left;
    padding: 0 0.25rem;
    line-height: 1.0625rem;
  }
  > :nth-last-child(2) {
    text-align: right;
  }
  font-weight: ${({ $selectedRow = false, $isLastRow = false, theme }) =>
    $selectedRow || $isLastRow ? theme.weights.bold : 'normal'};
`;

export const RowEntity = styled(Row)<{
  $incomeTypeLength?: number;
  $selectedRowEntity?: boolean;
}>`
  > :first-child {
    padding-left: 2rem;
  }
  font-size: 1rem;

  > :nth-last-child(2) {
    text-align: right;
  }
  font-weight: ${({ $selectedRowEntity, theme }) =>
    $selectedRowEntity ? theme.weights.bold : 'normal'};
`;

export const RowAccount = styled(Row)<{
  $incomeTypeLength?: number;
}>`
  > :first-child {
    padding-left: 4rem;
  }
  font-size: 0.9rem;

  > :nth-last-child(2) {
    text-align: right;
  }
`;

export const Col = styled.div``;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;

  div {
    margin: 3rem;
  }
`;

export const ColMoreInfo = styled.div`
  cursor: pointer;
  text-align: center;
  height: 1.5rem;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;

  &:hover {
    background: ${({ theme }) => theme.colors.primary50};
  }
`;

export const MoreInfo = styled.div``;

export const MoreInfoTable = styled.div<{
  $columns: number;
}>``;

export const PlusIcon = styled(DefaultPlusIcon)``;

export const MinusIcon = styled(DefaultMinusIcon)``;

export const Spinner = styled(DefaultSpinner)``;
