import { FC, memo } from 'react';

import { formatAmount, formatNumberDecimals } from 'utils/amounts';
import { formatPercentage } from 'utils/percentages';

import {
  ColCenter,
  ColLeft,
  Header,
  HeaderCell,
  HeaderTitle,
  Row,
  Table,
  TableContainer,
  Title,
} from './styles';
import type { Props } from './types';

const PositionByAccountAssetTable: FC<Props> = ({ data }) => {
  const headerColumns = [
    { titleColumn: 'Custodian' },
    { titleColumn: 'Entity' },
    { titleColumn: 'Account' },
    { titleColumn: 'Shares' },
    { titleColumn: 'Total (%)' },
    { titleColumn: 'Market Value' },
    { titleColumn: 'Cost' },
    { titleColumn: 'P/L ($)' },
  ];

  return (
    <TableContainer>
      <Table>
        <Header>
          {headerColumns.map((col) => (
            <HeaderCell key={col.titleColumn}>
              <HeaderTitle>
                <Title>{col.titleColumn}</Title>
              </HeaderTitle>
            </HeaderCell>
          ))}
        </Header>
        {data.map((row) => (
          <div key={`${row.custodian}-${row.account}`}>
            <Row key={`${row.custodian}-${row.account}`}>
              <ColLeft>{row.custodian}</ColLeft>
              <ColLeft>{row.entity}</ColLeft>
              <ColLeft>{row.account}</ColLeft>
              <ColCenter>{formatNumberDecimals(row.shares)}</ColCenter>
              <ColCenter>{formatPercentage(row.percTotal)}</ColCenter>
              <ColCenter>{formatAmount(row.marketValue)}</ColCenter>
              <ColCenter>{formatAmount(row.cost)}</ColCenter>
              <ColCenter>{formatAmount(row.totalProfitAndLoss)}</ColCenter>
            </Row>
          </div>
        ))}
      </Table>
    </TableContainer>
  );
};

export default memo(PositionByAccountAssetTable);
