import { FC, useEffect, useState } from 'react';

import BaseAssetDetailTable from 'components/BaseAssetDetailTable';
import CategoryDetailTable from 'components/CategoryDetailTable';
import from from 'styles/responsive';
import { formatDate } from 'utils/dates';
import exportToExcel from 'utils/exportToExcel';
import { KeySortOfColumn, OrderOrientation } from 'utils/sort/types';
import useMedia from 'utils/useMedia';
import usePlatform from 'utils/usePlatform/usePlatform';

import useConnect from './connect';
import {
  ActionZone,
  Amount,
  Button,
  Chevron,
  ColouredAmount,
  Container,
  ContainerMain,
  Content,
  ExportToExcelButton,
  FilterButton,
  FilterLabelContainer,
  GoBackContainer,
  Header,
  PandLTitle,
  RowContainer,
  Stats,
  StatsTitle,
  Title,
  Toolbar,
} from './styles';

const CategoryDetailSummary: FC = () => {
  const [sortColumn, setSortColumn] = useState<string>('Market Value');
  const [sortOrientation, setSortOrientation] = useState<string>(
    OrderOrientation.DESCENDENT,
  );
  const {
    assets,
    balance,
    totalPandL,
    mobileRows,
    parentUrl,
    search,
    pageTitle,
    notionalValues,
    subtypes,
    categorySlug,
    currentFiltering,
    filterOptions,
    currentSorting,
    handle,
    headerColumns,
  } = useConnect();
  const isTablet = useMedia(from.tablet.query);
  const isMobile = !useMedia(from.tablet.query);
  const { isWeb } = usePlatform();

  const showFilter = () =>
    categorySlug === 'equity-market' ||
    categorySlug === 'equity-individual' ||
    categorySlug === 'alternatives';

  const applyFilter = (value: string[]) => {
    handle.changeFiltering(value.join());
  };

  useEffect(() => {
    const currentSortingAux = currentSorting ?? 'default';

    const headerColumn = headerColumns.find(
      (col) =>
        col.keyOrderAsc === currentSortingAux ||
        col.keyOrderDesc === currentSortingAux,
    );

    if (headerColumn) {
      setSortColumn(headerColumn.titleColumn);
      setSortOrientation(
        headerColumn.keyOrderAsc === currentSortingAux
          ? OrderOrientation.ASCENDENT
          : OrderOrientation.DESCENDENT,
      );
    }
  }, [headerColumns, currentSorting]);

  const handleSortByColumn = (sortByColumnSelected: KeySortOfColumn) => {
    if (sortByColumnSelected.titleColumn === sortColumn) {
      handle.changeSorting(
        sortOrientation === OrderOrientation.DESCENDENT
          ? sortByColumnSelected.keyOrderAsc
          : sortByColumnSelected.keyOrderDesc,
      );
    } else if (sortByColumnSelected.titleColumn !== sortColumn) {
      handle.changeSorting(sortByColumnSelected.keyOrderDesc);
    }
  };

  const handleExportToExcel = () => {
    if (
      categorySlug === 'equity-market' ||
      categorySlug === 'equity-individual' ||
      categorySlug === 'commodity'
    ) {
      const data = assets.map((item) => ({
        Isin_Ticker: item.isinTicker ? item.isinTicker : 'N/A',
        Name: item.name,
        Price_As_Of_Date:
          item.priceAsOfDate > 0 ? formatDate(item.priceAsOfDate) : 'N/A',
        Price: item.marketPrice,
        Market_Value: item.marketValue,
        Total_Portfolio: item.totalPortfolioPercent,
        Cost: item.cost,
        Dividends_Received: item.dividendsReceived,
        Total_Profit_and_Loss: item.totalProfitAndLoss,
      }));

      void exportToExcel(
        data,
        `${pageTitle} List`,
        'CDlist',
        false,
        [4, 5, 7, 8, 9],
        [6],
      );
    } else {
      const data = assets.map((item) => ({
        Name: item.name,
        Price_As_Of_Date:
          item.priceAsOfDate > 0 ? formatDate(item.priceAsOfDate) : 'N/A',
        Price: item.marketPrice,
        Market_Value: item.marketValue,
        Total_Portfolio: item.totalPortfolioPercent,
        Cost: item.cost,
        Dividends_Received: item.dividendsReceived,
        Total_Profit_and_Loss: item.totalProfitAndLoss,
      }));

      void exportToExcel(
        data,
        `${pageTitle} List`,
        'CDlist',
        false,
        [3, 4, 6, 7, 8],
        [5],
      );
    }
  };

  return (
    <Container
      parentUrl={`${parentUrl}${search}`}
      title={`${pageTitle} Summary`}
    >
      {isTablet ? (
        <ContainerMain>
          <Header>
            <GoBackContainer>
              <Button
                color="secondary"
                variant="only-icon"
                size="small"
                withIcon={<Chevron />}
                href={`${parentUrl}`}
              />
              <Title>{`${pageTitle} Table`}</Title>
            </GoBackContainer>
            <Amount value={balance} />
            <RowContainer>
              <PandLTitle>Total P&L ($)</PandLTitle>
              <ColouredAmount value={totalPandL} />
            </RowContainer>
          </Header>
          <Content>
            {notionalValues && (
              <>
                <StatsTitle>Summary</StatsTitle>
                <Stats
                  category={pageTitle}
                  balance={notionalValues?.balance}
                  balancePct={notionalValues?.balancePct}
                  notional={notionalValues?.notional}
                  notionalPct={notionalValues?.notionalPct}
                  totalNotional={notionalValues?.totalNotional}
                  totalNotionalPct={notionalValues?.totalNotionalPct}
                  pathname="#"
                />
              </>
            )}
            {!isMobile && (
              <Toolbar>
                <ActionZone>
                  {isWeb && assets && assets.length > 0 && (
                    <ExportToExcelButton
                      size="small"
                      color="secondary"
                      onClick={handleExportToExcel}
                    >
                      Export to Excel
                    </ExportToExcelButton>
                  )}
                  {showFilter() && (
                    <FilterButton
                      onChangeMultiple={applyFilter}
                      options={filterOptions.filter(
                        (opt) => opt.value !== 'default',
                      )}
                      placeholder="Filter"
                      value={currentFiltering}
                      multiple
                    />
                  )}
                </ActionZone>
              </Toolbar>
            )}
            <CategoryDetailTable
              data={assets.map((asset) => ({
                id: asset.id,
                name: asset.name,
                shares: asset?.shares ?? 0,
                marketPrice: asset?.marketPrice ?? 0,
                marketValue: asset?.marketValue ?? 0,
                totalPorc: asset?.totalPortfolioPercent ?? 0,
                cost: asset?.cost ?? 0,
                dividendsReceived: asset?.dividendsReceived ?? 0,
                totalProfitLoss: asset?.totalProfitAndLoss ?? 0,
              }))}
              handleSortByColumn={handleSortByColumn}
              sortApplied={{
                titleColumn: sortColumn,
                orderOrientation: sortOrientation as OrderOrientation,
              }}
              headerColumns={headerColumns}
            />
          </Content>
        </ContainerMain>
      ) : (
        <>
          {subtypes && (
            <FilterLabelContainer>
              <div>
                Filtered by <strong>{subtypes.join(', ')}</strong>
              </div>
            </FilterLabelContainer>
          )}
          <Content>
            <BaseAssetDetailTable rows={mobileRows} />
          </Content>
        </>
      )}
    </Container>
  );
};

export default CategoryDetailSummary;
